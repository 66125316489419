:root {
  --color-primary-green: #5ec576;
  --color-primary: #dfc7d4;
  --color-secondary: #ffcb03;
  --color-tertiary: #ff585f;
  /* --color-primary-darker: #4bbb7d; */
  --color-primary-darker: #d1b0c1;
  --color-secondary-darker: #ffbb00;
  --color-tertiary-darker: #fd424b;
  --color-primary-opacity: #5ec5763a;
  --color-secondary-opacity: #ffcd0331;
  --color-tertiary-opacity: #ff58602d;
  --gradient-primary: linear-gradient(to top left, #39b385, #9be15d);
  --gradient-secondary: linear-gradient(to top left, #ffb003, #ffcb03);
}

* {
  margin: 0;
  padding: 0;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

html {
  font-size: 62.5%;
  box-sizing: border-box;
}

body {
  font-family: 'Poppins', sans-serif;
  font-weight: 300;
  color: #444;
  line-height: 1.9;
  background-color: #ffffff;
}

/* GENERAL ELEMENTS */

.section--1 {
  padding: 0 0 5rem 0;
  border-top: 1px solid #ddd;
  transition: transform 1s, opacity 1s;
}

.section--2 {
  padding: 2rem 0 3rem 0;
  border-top: 1px solid #ddd;
  transition: transform 1s, opacity 1s;
}

.section--3,
.section--4,
.section--5 {
  padding: 2rem 3rem;
  border-top: 1px solid #ddd;
  transition: transform 1s, opacity 1s;
}

.section--hidden {
  opacity: 0;
  transform: translateY(8rem);
}

.section__title {
  max-width: 80rem;
  margin: 6rem auto 8rem auto;
}

.section__header {
  font-size: 4rem;
  line-height: 1.3;
  font-weight: 500;
  text-align: center;
}

.btn {
  display: inline-block;
  background-color: var(--color-primary);
  color: inherit;
  font-size: 1.6rem;
  font-family: inherit;
  font-weight: 500;
  border: none;
  padding: 1.25rem 4.5rem;
  border-radius: 10rem;
  cursor: pointer;
  transition: all 0.3s;
}

.btn:hover {
  background-color: var(--color-primary-darker);
}

.btn__tab {
  display: inline-block;
  background-color: rgba(255, 255, 255);
  border: none;
  /* background-color: var(--color-primary); */
  /* border-bottom: 1px solid #5ec576; */
  font-size: 2rem;
  font-family: inherit;
  color: inherit;
  /* font-weight: 300; */
  text-transform: uppercase;

  padding: 1.25rem 4.5rem 0 4.5rem;
  /* border-radius: 10rem; */
  cursor: pointer;
  transition: all 0.3s;
}

/* .btn--text {
  display: inline-block;
  background: none;
  font-size: 1.7rem;
  font-family: inherit;
  font-weight: 500;
  color: var(--color-primary);
  border: none;
  border-bottom: 1px solid currentColor;
  padding-bottom: 2px;
  cursor: pointer;
  transition: all 0.3s;
} */

p {
  color: #666;
}

/* This is BAD for accessibility! Don't do in the real world! */
/* button:focus {
  outline: none;
} */

img {
  transition: filter 0.5s;
}

/* NAVIGATION */
.nav__main {
  width: 100%;
  /* padding: 0 3rem; */
  z-index: 100;
}

.nav__top {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 6rem;
  width: 100%;
  padding: 0 3rem;
  z-index: 100;
}

.nav {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 3rem;
  width: 100%;
  /* padding: 0 3rem; */
  z-index: 100;
}

/* nav and sticky class at the same time */
.nav__main.sticky {
  position: fixed;
  transition: all 1s;
  background-color: rgba(255, 255, 255, 0.95);
}

.nav__top--logo {
  height: 3.8rem;
  transition: all 0.3s;
  position: absolute;
  left: 43%;
}

.nav__links,
.nav__top--links {
  display: flex;
  align-items: center;
  list-style: none;
}

.nav__item,
.nav__top--item {
  margin-left: 4rem;
}

.nav__link:link,
.nav__link:visited,
.nav__top--link:link,
.nav__top--link:visited {
  font-size: 1.7rem;
  font-weight: 400;
  color: inherit;
  text-transform: uppercase;
  text-decoration: none;
  display: block;
  transition: all 0.3s;
}

.nav__top--link-btn:link,
.nav__top--link-btn:visited {
  padding: 0.8rem 2.5rem;
  border-radius: 3rem;
  background-color: var(--color-primary);
  color: #222;
}

.nav__top--link-btn:hover,
.nav__top--link-btn:active {
  color: inherit;
  background-color: var(--color-primary-darker);
  color: #333;
}

.nav____top--icon {
  height: 2.3rem;
  width: 2.3rem;
  margin-right: 1.5rem;
  margin-top: 1.1rem;
  display: none;
  /* transform: translateY(-1px); */
}

.nav__bar {
  display: block;
  width: 100%;
  height: 7px;
  background-color: #101010;
}

/* header > hamburger attributes. This is hidden at first which also hides the 3 bars*/
.hamburger {
  display: none;
}

/* header > hamburger bars attributes. */
.bar {
  display: block;
  width: 25px;
  height: 3px;
  margin: 5px auto;
  transition: all 0.3s ease-in-out;
  background-color: #101010;
}

/* MAIN SLIDER */
.mainslider {
  width: 100%;
  height: auto;
  min-height: 100vh;
  margin: 0;
  position: relative;
  overflow: hidden;
}

.mainslide {
  position: absolute;
  top: 0;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  min-height: 100vh;

  /* THIS creates the animation! */
  transition: transform 1s;
}

.mainslide > img {
  /* Only for images that have different size than slide */
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.mainslide > video {
  /* Only for images that have different size than slide */
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.mainslider__btn {
  position: absolute;
  top: 40%;
  z-index: 10;
  background: none;
  border: none;
  fill: #888;
  height: 5.5rem;
  width: 5.5rem;
  cursor: pointer;
}

.mainslider__btn--right {
  right: 5%;
  /* transform: translate(50%, -50%); */
}

/* FEATURES */
.features {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 4rem;
  margin: 0 4rem;
}

.features__img {
  width: 100%;
}

.features__feature {
  align-self: center;
  justify-self: center;
  width: 90%;
  font-size: 1.6rem;
}

.features__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-primary-opacity);
  height: 5.5rem;
  width: 5.5rem;
  border-radius: 50%;
  margin-bottom: 2rem;
}

.features__icon svg {
  height: 2.5rem;
  width: 2.5rem;
  fill: var(--color-primary);
}

.features__header {
  font-size: 2rem;
  margin-bottom: 1rem;
}

/* OPERATIONS */
.operations {
  max-width: 300rem;
  margin: 5rem 0 0 0;
  background-color: #fff;
}

.operations__tab-container {
  display: flex;
  justify-content: center;
}

.operations__tab {
  margin-right: 2.5rem;
  transform: translateY(-50%);
}

.operations__tab span {
  /* margin-right: 1rem; */
  font-weight: 500;
  display: inline-block;
}

.operations__tab--1:hover {
  color: var(--color-secondary);
}

.operations__tab--2:hover {
  color: var(--color-primary-green);
}

.operations__tab--3:hover {
  color: var(--color-tertiary);
}

.operations__tab--active {
  transform: translateY(-66%);
  border-bottom: 1px solid #999999;
  transition: all 1s;
}

.operations__content {
  display: none;
}

.operations__content--active {
  display: grid;
}

.operations__header {
  font-size: 2.25rem;
  font-weight: 500;
  align-self: center;
}

.operations__img {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  overflow: hidden;
  min-height: 100vh;

  /* THIS creates the animation! */
  transition: transform 1s;
}

.operations__img > video {
  /* Only for images that have different size than slide */
  width: 100%;
  height: auto;
  object-fit: contain;
}

/* SLIDER */
.slider {
  max-width: 100rem;
  height: 43rem;
  margin: 0 auto;
  position: relative;

  /* IN THE END */
  overflow: hidden;
}

.slide {
  position: absolute;
  top: 0;
  width: 100%;
  height: 40rem;

  display: flex;
  align-items: center;
  justify-content: center;

  /* THIS creates the animation! */
  transition: transform 1s;
}

.slide > img {
  /* Only for images that have different size than slide */
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.slider__btn {
  position: absolute;
  top: 50%;
  z-index: 10;

  border: none;
  background: rgba(255, 255, 255, 0.7);
  font-family: inherit;
  color: #888;
  border-radius: 50%;
  height: 5.5rem;
  width: 5.5rem;
  font-size: 3.25rem;
  cursor: pointer;
}

.slider__btn--left {
  left: 6%;
  transform: translate(-50%, -50%);
}

.slider__btn--right {
  right: 6%;
  transform: translate(50%, -50%);
}

.dots {
  position: absolute;
  bottom: 5%;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
}

.dots__dot {
  border: none;
  background-color: #b9b9b9;
  opacity: 0.7;
  height: 1rem;
  width: 1rem;
  border-radius: 50%;
  margin-right: 1.75rem;
  cursor: pointer;
  transition: all 0.5s;
}

.dots__dot:last-child {
  margin: 0;
}

.dots__dot--active {
  background-color: #888;
  opacity: 1;
}

/* TESTIMONIALS */
.testimonial {
  width: 65%;
  position: relative;
}

.testimonial::before {
  content: '\201C';
  position: absolute;
  top: -5.7rem;
  left: -6.8rem;
  line-height: 1;
  font-size: 20rem;
  font-family: inherit;
  color: var(--color-primary);
  z-index: -1;
}

.testimonial__header {
  font-size: 2.25rem;
  font-weight: 500;
  margin-bottom: 1.5rem;
}

.testimonial__text {
  font-size: 1.7rem;
  margin-bottom: 3.5rem;
  color: #666;
}

.testimonial__author {
  font-style: normal;
}

.testimonial__name {
  font-size: 1.7rem;
  font-weight: 500;
  align-self: end;
  margin: 0;
}

.testimonial__location {
  font-size: 1.5rem;
}

.section__title--testimonials {
  margin-bottom: 4rem;
}

/* CONTACT */

.contacts {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
  margin: 0 2rem;
}

.contacts__contact {
  font-size: 1.6rem;
  text-align: center;
  width: 100%;
}

.contacts__header,
.contacts__location {
  font-size: 2rem;
  margin-bottom: 1rem;
  text-transform: uppercase;
}

.contacts__detail {
  text-align: left;
  display: inline-block;
}

/* SIGNUP */

.footer--logo {
  height: 3.8rem;
  transition: all 0.3s;
  text-align: center;
}

.section--sign-up {
  background-color: #000000;
  border-top: none;
  border-bottom: 1px solid #444;
  text-align: center;
  padding: 10rem 3rem;
}

.section--sign-up .section__header {
  color: #fff;
  text-align: center;
}

.section--sign-up .section__title {
  margin-bottom: 6rem;
}

.section--sign-up .btn {
  font-size: 1.9rem;
  padding: 2rem 5rem;
}

/* FOOTER */
.footer {
  padding: 10rem 3rem;
  background-color: #000000;
}

.footer__logo {
  height: 5rem;
  display: block;
  margin: 0 auto;
  margin-bottom: 5rem;
}

.footer__copyright {
  font-size: 1.4rem;
  color: #aaa;
  text-align: center;
}

.footer__copyright .footer__link {
  font-size: 1.4rem;
}

.facebook__icon {
  display: block;
  text-align: center;
  margin-bottom: 2rem;
}

.facebook__icon svg {
  height: 5rem;
  width: 5rem;
  fill: #666;
}

.facebook__icon svg:hover {
  fill: #f3f3f3;
}

/* MODAL WINDOW */
/* .modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 60rem;
  background-color: #f3f3f3;
  padding: 5rem 6rem;
  box-shadow: 0 4rem 6rem rgba(0, 0, 0, 0.3);
  z-index: 1000;
  transition: all 0.5s;
} */

/* .overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(4px);
  z-index: 100;
  transition: all 0.5s;
}

.modal__header {
  font-size: 3.25rem;
  margin-bottom: 4.5rem;
  line-height: 1.5;
}

.modal__form {
  margin: 0 3rem;
  display: grid;
  grid-template-columns: 1fr 2fr;
  align-items: center;
  gap: 2.5rem;
}

.modal__form label {
  font-size: 1.7rem;
  font-weight: 500;
}

.modal__form input {
  font-size: 1.7rem;
  padding: 1rem 1.5rem;
  border: 1px solid #ddd;
  border-radius: 0.5rem;
}

.modal__form button {
  grid-column: 1 / span 2;
  justify-self: center;
  margin-top: 1rem;
}

.btn--close-modal {
  font-family: inherit;
  color: inherit;
  position: absolute;
  top: 0.5rem;
  right: 2rem;
  font-size: 4rem;
  cursor: pointer;
  border: none;
  background: none;
} */

.hidden {
  visibility: hidden;
  opacity: 0;
}

/* COOKIE MESSAGE */
.cookie-message {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  background-color: white;
  color: #bbb;
  font-size: 1.5rem;
  font-weight: 400;
}

/* RESPONSIVE */

@media only screen and (max-width: 61.25em) {
  /*Hide the navigation menu list when viewport is less than 980px. */

  .nav {
    height: 0;
  }

  .nav__top {
    height: 4rem;
  }
  .nav__top--logo {
    height: 2.5rem;
    transition: all 0.3s;
    position: absolute;
    left: 38%;
  }
  .footer--logo {
    height: 2.5rem;
    transition: all 0.3s;
  }

  .nav__links {
    position: fixed;
    display: none;
    right: 0;
    top: 8rem;
    background-color: #ffffff;
    border-radius: 10px;
    text-align: left;
    transition: 0.3s;
    box-shadow: 0 10px 27px rgba(0, 0, 0, 0.05);
  }

  /* header > nav list attributes (<900px). Show the navigation menu list upon click of hamburger menu (performed by JS to add "active" class). Display them in block so that it appears vertically with right 0 margin*/
  .nav__links.active {
    right: 0;
    display: block;
    /* flex-direction: column; */
    height: 100%;
    width: 20rem;
    z-index: 20;
  }

  /* header > nav item attributes (<900px). Set 0 margin so that the hover shade covers the entire menu row. Set miminum height so that it is spaced out vertically. Set padding so that the words are centered. */
  .nav__item {
    margin-left: 2rem;
    min-height: 5rem;
    padding-top: 1.5rem;
  }

  .nav____top--icon {
    display: inline;
  }

  .nav__top--contact-item {
    display: none;
  }
  /* header > hamburger attributes. Show the hamburger 3 bars menu.*/
  .hamburger {
    display: block;
    cursor: pointer;
    /* margin-top: 0.5rem; */
  }

  /* header > hamburger attributes. Animate the 3 bars.*/
  .hamburger.active .bar:nth-child(2) {
    opacity: 0;
  }

  .hamburger.active .bar:nth-child(1) {
    transform: translateY(8px) rotate(45deg);
  }

  .hamburger.active .bar:nth-child(3) {
    transform: translateY(-8px) rotate(-45deg);
  }

  .mainslider__btn {
    top: 25%;
    height: 3rem;
    width: 3rem;
  }

  .mainslider {
    min-height: 100vh;
  }

  .mainslide {
    min-height: 70vh;
  }

  .btn__tab {
    font-size: 1.6rem;
  }

  .operations {
    margin: 2.8rem 0 0 0;
  }

  .operations__tab {
    margin-right: 0;
  }

  .operations__img {
    min-height: 50vh;
  }

  .section__title {
    margin: 3rem auto 4rem auto;
  }

  .section__header {
    font-size: 2rem;
    line-height: 1.3;
  }

  .features {
    margin: 0;
  }

  .features__header {
    font-size: 1.6rem;
  }

  .features__feature {
    font-size: 1.4rem;
  }

  .testimonial__header {
    font-size: 1.8rem;
  }

  .testimonial__text {
    font-size: 1.4rem;
  }

  .section--sign-up .btn {
    font-size: 1.6rem;
  }
}

@media only screen and (max-width: 40.5em) {
  /*Hide the navigation menu list when viewport is less than 648px.*/
  .nav__top--logo {
    height: 2.5rem;
    transition: all 0.3s;
    position: absolute;
    left: 35%;
  }

  .mainslider__btn {
    top: 32%;
  }

  .mainslider {
    min-height: 70vh;
  }

  .mainslide {
    min-height: 50vh;
  }

  .btn__tab {
    font-size: 1.3rem;
    padding: 1.25rem 2rem 0 2rem;
  }

  .features {
    display: grid;
    grid-template-columns: 1fr;
    gap: 2rem;
    margin: 0;
  }

  .slider__btn {
    font-size: 2rem;
  }

  .contacts__contact {
    font-size: 1.4rem;
  }

  .contacts__header,
  .contacts__location {
    font-size: 1.6rem;
  }

  .section--sign-up .btn {
    font-size: 1.5rem;
    padding: 1rem 2rem;
  }
}

@media only screen and (max-width: 28em) {
  /*Hide the navigation menu list when viewport is less than 448px.*/
  .nav__top--logo {
    height: 2rem;
    transition: all 0.3s;
    position: absolute;
    left: 30%;
  }

  .mainslider {
    min-height: 80vh;
  }

  .mainslider__btn {
    top: 18%;
  }

  .nav____top--icon {
    height: 2rem;
    width: 2rem;
  }

  .operations__img {
    min-height: 38vh;
  }

  .testimonial::before {
    top: -3.7rem;
    left: -4rem;
    font-size: 12rem;
  }

  .slider {
    height: 57rem;
  }

  .slide {
    height: 55rem;
  }

  .contacts {
    display: grid;
    grid-template-columns: 1fr;
    gap: 2rem;
    margin: 0 2rem;
  }
}

@media only screen and (max-width: 21.5em) {
  /*Hide the navigation menu list when viewport is less than 344px.*/
  .nav__top--logo {
    height: 1.7rem;
    transition: all 0.3s;
    position: absolute;
    left: 27%;
  }

  .mainslider__btn {
    top: 15%;
  }

  .nav____top--icon {
    height: 1.7rem;
    width: 1.7rem;
  }

  .operations__img {
    min-height: 28vh;
  }

  .testimonial__header,
  .testimonial__name {
    font-size: 1.6rem;
  }
}
