:root {
  --color-primary-green: #5ec576;
  --color-primary: #dfc7d4;
  --color-secondary: #ffcb03;
  --color-tertiary: #ff585f;
  --color-primary-darker: #d1b0c1;
  --color-secondary-darker: #fb0;
  --color-tertiary-darker: #fd424b;
  --color-primary-opacity: #5ec5763a;
  --color-secondary-opacity: #ffcd0331;
  --color-tertiary-opacity: #ff58602d;
  --gradient-primary: linear-gradient(to top left, #39b385, #9be15d);
  --gradient-secondary: linear-gradient(to top left, #ffb003, #ffcb03);
}

* {
  margin: 0;
  padding: 0;
}

*, :before, :after {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  font-size: 62.5%;
}

body {
  color: #444;
  background-color: #fff;
  font-family: Poppins, sans-serif;
  font-weight: 300;
  line-height: 1.9;
}

.section--1 {
  border-top: 1px solid #ddd;
  padding: 0 0 5rem;
  transition: transform 1s, opacity 1s;
}

.section--2 {
  border-top: 1px solid #ddd;
  padding: 2rem 0 3rem;
  transition: transform 1s, opacity 1s;
}

.section--3, .section--4, .section--5 {
  border-top: 1px solid #ddd;
  padding: 2rem 3rem;
  transition: transform 1s, opacity 1s;
}

.section--hidden {
  opacity: 0;
  transform: translateY(8rem);
}

.section__title {
  max-width: 80rem;
  margin: 6rem auto 8rem;
}

.section__header {
  text-align: center;
  font-size: 4rem;
  font-weight: 500;
  line-height: 1.3;
}

.btn {
  background-color: var(--color-primary);
  color: inherit;
  cursor: pointer;
  border: none;
  border-radius: 10rem;
  padding: 1.25rem 4.5rem;
  font-family: inherit;
  font-size: 1.6rem;
  font-weight: 500;
  transition: all .3s;
  display: inline-block;
}

.btn:hover {
  background-color: var(--color-primary-darker);
}

.btn__tab {
  color: inherit;
  text-transform: uppercase;
  cursor: pointer;
  background-color: #fff;
  border: none;
  padding: 1.25rem 4.5rem 0;
  font-family: inherit;
  font-size: 2rem;
  transition: all .3s;
  display: inline-block;
}

p {
  color: #666;
}

img {
  transition: filter .5s;
}

.nav__main {
  width: 100%;
  z-index: 100;
}

.nav__top {
  height: 6rem;
  width: 100%;
  z-index: 100;
  justify-content: flex-end;
  align-items: center;
  padding: 0 3rem;
  display: flex;
}

.nav {
  height: 3rem;
  width: 100%;
  z-index: 100;
  justify-content: center;
  align-items: center;
  display: flex;
}

.nav__main.sticky {
  background-color: #fffffff2;
  transition: all 1s;
  position: fixed;
}

.nav__top--logo {
  height: 3.8rem;
  transition: all .3s;
  position: absolute;
  left: 43%;
}

.nav__links, .nav__top--links {
  align-items: center;
  list-style: none;
  display: flex;
}

.nav__item, .nav__top--item {
  margin-left: 4rem;
}

.nav__link:link, .nav__link:visited, .nav__top--link:link, .nav__top--link:visited {
  color: inherit;
  text-transform: uppercase;
  font-size: 1.7rem;
  font-weight: 400;
  text-decoration: none;
  transition: all .3s;
  display: block;
}

.nav__top--link-btn:link, .nav__top--link-btn:visited {
  background-color: var(--color-primary);
  color: #222;
  border-radius: 3rem;
  padding: .8rem 2.5rem;
}

.nav__top--link-btn:hover, .nav__top--link-btn:active {
  color: inherit;
  background-color: var(--color-primary-darker);
  color: #333;
}

.nav____top--icon {
  height: 2.3rem;
  width: 2.3rem;
  margin-top: 1.1rem;
  margin-right: 1.5rem;
  display: none;
}

.nav__bar {
  width: 100%;
  height: 7px;
  background-color: #101010;
  display: block;
}

.hamburger {
  display: none;
}

.bar {
  width: 25px;
  height: 3px;
  background-color: #101010;
  margin: 5px auto;
  transition: all .3s ease-in-out;
  display: block;
}

.mainslider {
  width: 100%;
  height: auto;
  min-height: 100vh;
  margin: 0;
  position: relative;
  overflow: hidden;
}

.mainslide {
  width: 100%;
  height: auto;
  min-height: 100vh;
  justify-content: center;
  align-items: flex-start;
  transition: transform 1s;
  display: flex;
  position: absolute;
  top: 0;
}

.mainslide > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.mainslide > video {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.mainslider__btn {
  z-index: 10;
  fill: #888;
  height: 5.5rem;
  width: 5.5rem;
  cursor: pointer;
  background: none;
  border: none;
  position: absolute;
  top: 40%;
}

.mainslider__btn--right {
  right: 5%;
}

.features {
  grid-template-columns: 1fr 1fr;
  gap: 4rem;
  margin: 0 4rem;
  display: grid;
}

.features__img {
  width: 100%;
}

.features__feature {
  width: 90%;
  place-self: center;
  font-size: 1.6rem;
}

.features__icon {
  background-color: var(--color-primary-opacity);
  height: 5.5rem;
  width: 5.5rem;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
  display: flex;
}

.features__icon svg {
  height: 2.5rem;
  width: 2.5rem;
  fill: var(--color-primary);
}

.features__header {
  margin-bottom: 1rem;
  font-size: 2rem;
}

.operations {
  max-width: 300rem;
  background-color: #fff;
  margin: 5rem 0 0;
}

.operations__tab-container {
  justify-content: center;
  display: flex;
}

.operations__tab {
  margin-right: 2.5rem;
  transform: translateY(-50%);
}

.operations__tab span {
  font-weight: 500;
  display: inline-block;
}

.operations__tab--1:hover {
  color: var(--color-secondary);
}

.operations__tab--2:hover {
  color: var(--color-primary-green);
}

.operations__tab--3:hover {
  color: var(--color-tertiary);
}

.operations__tab--active {
  border-bottom: 1px solid #999;
  transition: all 1s;
  transform: translateY(-66%);
}

.operations__content {
  display: none;
}

.operations__content--active {
  display: grid;
}

.operations__header {
  align-self: center;
  font-size: 2.25rem;
  font-weight: 500;
}

.operations__img {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  justify-content: center;
  align-items: flex-start;
  transition: transform 1s;
  display: flex;
  overflow: hidden;
}

.operations__img > video {
  width: 100%;
  height: auto;
  object-fit: contain;
}

.slider {
  max-width: 100rem;
  height: 43rem;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
}

.slide {
  width: 100%;
  height: 40rem;
  justify-content: center;
  align-items: center;
  transition: transform 1s;
  display: flex;
  position: absolute;
  top: 0;
}

.slide > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.slider__btn {
  z-index: 10;
  color: #888;
  height: 5.5rem;
  width: 5.5rem;
  cursor: pointer;
  background: #ffffffb3;
  border: none;
  border-radius: 50%;
  font-family: inherit;
  font-size: 3.25rem;
  position: absolute;
  top: 50%;
}

.slider__btn--left {
  left: 6%;
  transform: translate(-50%, -50%);
}

.slider__btn--right {
  right: 6%;
  transform: translate(50%, -50%);
}

.dots {
  display: flex;
  position: absolute;
  bottom: 5%;
  left: 50%;
  transform: translateX(-50%);
}

.dots__dot {
  opacity: .7;
  height: 1rem;
  width: 1rem;
  cursor: pointer;
  background-color: #b9b9b9;
  border: none;
  border-radius: 50%;
  margin-right: 1.75rem;
  transition: all .5s;
}

.dots__dot:last-child {
  margin: 0;
}

.dots__dot--active {
  opacity: 1;
  background-color: #888;
}

.testimonial {
  width: 65%;
  position: relative;
}

.testimonial:before {
  content: "“";
  color: var(--color-primary);
  z-index: -1;
  font-family: inherit;
  font-size: 20rem;
  line-height: 1;
  position: absolute;
  top: -5.7rem;
  left: -6.8rem;
}

.testimonial__header {
  margin-bottom: 1.5rem;
  font-size: 2.25rem;
  font-weight: 500;
}

.testimonial__text {
  color: #666;
  margin-bottom: 3.5rem;
  font-size: 1.7rem;
}

.testimonial__author {
  font-style: normal;
}

.testimonial__name {
  align-self: end;
  margin: 0;
  font-size: 1.7rem;
  font-weight: 500;
}

.testimonial__location {
  font-size: 1.5rem;
}

.section__title--testimonials {
  margin-bottom: 4rem;
}

.contacts {
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
  margin: 0 2rem;
  display: grid;
}

.contacts__contact {
  text-align: center;
  width: 100%;
  font-size: 1.6rem;
}

.contacts__header, .contacts__location {
  text-transform: uppercase;
  margin-bottom: 1rem;
  font-size: 2rem;
}

.contacts__detail {
  text-align: left;
  display: inline-block;
}

.footer--logo {
  height: 3.8rem;
  text-align: center;
  transition: all .3s;
}

.section--sign-up {
  text-align: center;
  background-color: #000;
  border-top: none;
  border-bottom: 1px solid #444;
  padding: 10rem 3rem;
}

.section--sign-up .section__header {
  color: #fff;
  text-align: center;
}

.section--sign-up .section__title {
  margin-bottom: 6rem;
}

.section--sign-up .btn {
  padding: 2rem 5rem;
  font-size: 1.9rem;
}

.footer {
  background-color: #000;
  padding: 10rem 3rem;
}

.footer__logo {
  height: 5rem;
  margin: 0 auto 5rem;
  display: block;
}

.footer__copyright {
  color: #aaa;
  text-align: center;
  font-size: 1.4rem;
}

.footer__copyright .footer__link {
  font-size: 1.4rem;
}

.facebook__icon {
  text-align: center;
  margin-bottom: 2rem;
  display: block;
}

.facebook__icon svg {
  height: 5rem;
  width: 5rem;
  fill: #666;
}

.facebook__icon svg:hover {
  fill: #f3f3f3;
}

.hidden {
  visibility: hidden;
  opacity: 0;
}

.cookie-message {
  width: 100%;
  color: #bbb;
  background-color: #fff;
  justify-content: space-evenly;
  align-items: center;
  font-size: 1.5rem;
  font-weight: 400;
  display: flex;
}

@media only screen and (max-width: 61.25em) {
  .nav {
    height: 0;
  }

  .nav__top {
    height: 4rem;
  }

  .nav__top--logo {
    height: 2.5rem;
    transition: all .3s;
    position: absolute;
    left: 38%;
  }

  .footer--logo {
    height: 2.5rem;
    transition: all .3s;
  }

  .nav__links {
    text-align: left;
    background-color: #fff;
    border-radius: 10px;
    transition: all .3s;
    display: none;
    position: fixed;
    top: 8rem;
    right: 0;
    box-shadow: 0 10px 27px #0000000d;
  }

  .nav__links.active {
    height: 100%;
    width: 20rem;
    z-index: 20;
    display: block;
    right: 0;
  }

  .nav__item {
    min-height: 5rem;
    margin-left: 2rem;
    padding-top: 1.5rem;
  }

  .nav____top--icon {
    display: inline;
  }

  .nav__top--contact-item {
    display: none;
  }

  .hamburger {
    cursor: pointer;
    display: block;
  }

  .hamburger.active .bar:nth-child(2) {
    opacity: 0;
  }

  .hamburger.active .bar:nth-child(1) {
    transform: translateY(8px)rotate(45deg);
  }

  .hamburger.active .bar:nth-child(3) {
    transform: translateY(-8px)rotate(-45deg);
  }

  .mainslider__btn {
    height: 3rem;
    width: 3rem;
    top: 25%;
  }

  .mainslider {
    min-height: 100vh;
  }

  .mainslide {
    min-height: 70vh;
  }

  .btn__tab {
    font-size: 1.6rem;
  }

  .operations {
    margin: 2.8rem 0 0;
  }

  .operations__tab {
    margin-right: 0;
  }

  .operations__img {
    min-height: 50vh;
  }

  .section__title {
    margin: 3rem auto 4rem;
  }

  .section__header {
    font-size: 2rem;
    line-height: 1.3;
  }

  .features {
    margin: 0;
  }

  .features__header {
    font-size: 1.6rem;
  }

  .features__feature {
    font-size: 1.4rem;
  }

  .testimonial__header {
    font-size: 1.8rem;
  }

  .testimonial__text {
    font-size: 1.4rem;
  }

  .section--sign-up .btn {
    font-size: 1.6rem;
  }
}

@media only screen and (max-width: 40.5em) {
  .nav__top--logo {
    height: 2.5rem;
    transition: all .3s;
    position: absolute;
    left: 35%;
  }

  .mainslider__btn {
    top: 32%;
  }

  .mainslider {
    min-height: 70vh;
  }

  .mainslide {
    min-height: 50vh;
  }

  .btn__tab {
    padding: 1.25rem 2rem 0;
    font-size: 1.3rem;
  }

  .features {
    grid-template-columns: 1fr;
    gap: 2rem;
    margin: 0;
    display: grid;
  }

  .slider__btn {
    font-size: 2rem;
  }

  .contacts__contact {
    font-size: 1.4rem;
  }

  .contacts__header, .contacts__location {
    font-size: 1.6rem;
  }

  .section--sign-up .btn {
    padding: 1rem 2rem;
    font-size: 1.5rem;
  }
}

@media only screen and (max-width: 28em) {
  .nav__top--logo {
    height: 2rem;
    transition: all .3s;
    position: absolute;
    left: 30%;
  }

  .mainslider {
    min-height: 80vh;
  }

  .mainslider__btn {
    top: 18%;
  }

  .nav____top--icon {
    height: 2rem;
    width: 2rem;
  }

  .operations__img {
    min-height: 38vh;
  }

  .testimonial:before {
    font-size: 12rem;
    top: -3.7rem;
    left: -4rem;
  }

  .slider {
    height: 57rem;
  }

  .slide {
    height: 55rem;
  }

  .contacts {
    grid-template-columns: 1fr;
    gap: 2rem;
    margin: 0 2rem;
    display: grid;
  }
}

@media only screen and (max-width: 21.5em) {
  .nav__top--logo {
    height: 1.7rem;
    transition: all .3s;
    position: absolute;
    left: 27%;
  }

  .mainslider__btn {
    top: 15%;
  }

  .nav____top--icon {
    height: 1.7rem;
    width: 1.7rem;
  }

  .operations__img {
    min-height: 28vh;
  }

  .testimonial__header, .testimonial__name {
    font-size: 1.6rem;
  }
}

/*# sourceMappingURL=index.498c3292.css.map */
